import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Services from "./Components/Services";
import Projects from "./Components/Projects";
import Partners from "./Components/Partners";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="bg-gray-900">
    <Navbar />
    <Home />
    <Services />
    <Projects />
    <Partners />
    <Footer />
    </div>
  );
}

export default App;
