import React from 'react'
import Utilify from '../Images/utilify.png'
import Skyrealfi from '../Images/skyrealfi.png'

const Partners = () => {
  return (
      <section id='partners' class="bg-gray-900 text-white mx-10 mt-20">
          <div class="mx-auto max-w-lg text-center mb-20 mt-8">
             <h2 class="text-3xl font-bold sm:text-4xl">Partners</h2>
          </div>

          <div class="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4 mb-20">
            <a href='https://utilify.xyz/' target='_blank'><img src={Utilify} alt="Tuple" class="h-16 mx-auto" /></a>
            <a href='https://skyrealfiprotocol.com/' target='_blank'><img src={Skyrealfi} alt="Tuple" class="h-16 mx-auto" /></a>
          </div>
      </section>
  )
}

export default Partners