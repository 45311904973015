import React from 'react'

const Home = () => {
  return (
<section id='home' class="bg-gray-900 text-white mt-20">
  <div
    class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen-xl	 lg:items-center"
  >
    <div class="mx-auto max-w-3xl text-center">
      <h1
        class="bg-gradient-to-r from-green-200 via-blue-400 to-purple-400 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
      >
        Creating Blockchain oppurtunities for everyone.
      </h1>

      <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
        We invest and support potential projects as well as producing quality projects at the Blockchain institute.
      </p>

      <div class="mt-12 flex flex-wrap justify-center gap-4">
        <a
          class="block w-full rounded-xl border border-blue-600 bg-blue-600 px-10 py-3 text-sm font-medium text-white hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
          href="mailto:info@zenithcore.io"
        >
          Contact us
        </a>
      </div>
    </div>
  </div>
</section>
  )
}

export default Home