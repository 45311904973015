import React from 'react'
import Partner1 from '../Images/zc-logo.png'


const Projects = () => {
  return (
    <section  class="bg-gray-900 text-white mx-10 mt-20">

        <div class="mx-auto max-w-lg text-center mb-20 mt-8">
           <h2 class="text-3xl font-bold sm:text-4xl">Projects</h2>
        </div>

        <div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mb-20">

        <a href='/'> 
            <div class="bg-gray-900 border-gray-800 border transition hover:border-blue-500/10 hover:shadow-blue-500/10 rounded-lg shadow-lg overflow-hidden p-5">
              <img src={Partner1} alt="Tuple" class=" h-18 mx-auto object-cover" />
              <div class="p-6">
                <h3 class="tracking-wide text-white text-xs font-medium uppercase">Project Name</h3>
                <p class="mt-3 text-white text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi malesuada, quam eu aliquet imperdiet, turpis massa tincidunt nunc, eget viverra nibh massa eget arcu. Fusce vulputate semper venenatis. </p>
              </div>
            </div>
          </a>
        </div>
    </section>
  )
}

export default Projects