import React from 'react'
import Logo from '../Images/zc-logo.png'
import { BsTwitter } from 'react-icons/bs'
import { FaMedium } from 'react-icons/fa' 
import { SiLinktree } from 'react-icons/si'
import { FaGithub } from 'react-icons/fa'

const Footer = () => {
  return (
    <div>
      <footer aria-label="Site Footer" class="bg-gray-900">
  <div class="mx-auto max-w-2xl px-4 py-8 sm:px-6 lg:px-8">
    <div class="flex justify-center text-teal-600">
          <img src={Logo} class="h-12 sm:h-16" alt="ZenithCore Logo" />
    </div>

    <p class="mx-auto mt-6 max-w-md text-center leading-relaxed text-white">
    We invest and support potential projects as well as producing quality projects at the Blockchain institute.
    </p>


    <ul class="mt-12 flex justify-center gap-6 md:gap-8">
      <li>
        <a
          href="https://twitter.com/ZenithCoreLabs"
          rel="noreferrer"
          target="_blank"
          class="text-white transition hover:text-white/75 text-2xl"
        >
          <BsTwitter />
        </a>
      </li>

      <li>
        <a
          href="https://medium.com/@info_55534"
          rel="noreferrer"
          target="_blank"
          class="text-white transition hover:text-white/75 text-2xl"
        >
          <FaMedium />
        </a>
      </li>

      <li>
        <a
          href="https://github.com/ZenithCoreLabs"
          rel="noreferrer"
          target="_blank"
          class="text-white transition hover:text-white/75 text-2xl"
        >
          <FaGithub />
        </a>
      </li>

      <li>
        <a
          href="https://linktr.ee/zenithcorelabs"
          rel="noreferrer"
          target="_blank"
          class="text-white transition hover:text-white/75 text-2xl"
        >
          <SiLinktree />
        </a>
      </li>
    </ul>
    <p class="mx-auto mt-8 text-center text-white">
    ©2023 All Rights Reserved by ZenithCore Labs
    </p>
  </div>
</footer>

    </div>
  )
}

export default Footer