import React from 'react'
import { GiTeacher } from 'react-icons/gi'
import { FaNetworkWired } from 'react-icons/fa'
import { RiAdvertisementFill } from 'react-icons/ri'
import { BsCodeSlash } from 'react-icons/bs'
import {BsCurrencyExchange } from 'react-icons/bs'
import { MdOutlineBusiness } from 'react-icons/md'
import { MdOutlineConnectWithoutContact } from 'react-icons/md'
import { ImRocket } from 'react-icons/im'

const Services = () => {
  return (
<section  class="bg-gray-900 text-white">
  <div class="mx-auto max-w-screen-l px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    
    <div class="mx-auto max-w-lg text-center">
      <h2 id='services' class="text-3xl font-bold sm:text-4xl mt-12">Services</h2>
    </div>

    <div  class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >

        <GiTeacher  className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Web3 Consultancy</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >
        <FaNetworkWired className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Capital Network</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >

        < RiAdvertisementFill className="h-10 w-10 text-blue-600" />
        <h2 class="mt-4 text-xl font-bold text-white">Marketing</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >

        <BsCodeSlash className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Smart Contract Development</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >
        <BsCodeSlash className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Fullstack Project Development</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >
        <BsCurrencyExchange className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Exchange Listing</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >
        <MdOutlineBusiness className="h-10 w-10 text-blue-600" />

        <h2 class="mt-4 text-xl font-bold text-white">Business Development</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >

        <MdOutlineConnectWithoutContact   className="h-10 w-10 text-blue-600" />
        <h2 class="mt-4 text-xl font-bold text-white">KOL's Support</h2>

        <p class="mt-1 text-sm text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>

      <a
        class="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10"
        href="/services/digital-campaigns"
      >

        <ImRocket  className="h-10 w-10 text-blue-600" />
        <h2 class="mt-4 text-xl font-bold text-white">IDO Network</h2>

        <p id='projects' class="mt-1 text-sm text-gray-300 ">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium.
        </p>
      </a>
      
    </div>
  </div>
</section>

  )
}

export default Services